<template>
  <section
    :class="$customSectionClasses(props.cfgs)"
  >
    <div class="header-block-root">
      <NuxtImg
        fit="contain"
        width="1920"
        height="784"
        loading="lazy"
        :src="String(values.background)"
        alt="background"
        class="adaptive-img"
      />
      <div class="container">
        <div class="header-block-content">
          <img
            src="../../assets/img/png/header/logo.png"
            alt="logo"
          >

          <h1 class="text">{{ values.text }}</h1>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';

const { $customSectionClasses } = useNuxtApp();

type HeaderBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs'> & {
  values: {
    background: number,
    icon: 66,
    text: string,
    title: string
  },
  cfgs: SectionTypes;
};

const props = defineProps<HeaderBlockType>();
</script>

<style scoped lang="scss">
.header-block-root {
  position: relative;
  top: -82px;
  z-index: -1;
  height: 784px;
  margin-bottom: -82px;

  & > img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }
}

.header-block-content {
  padding-top: 283px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  & > img {
    width: 381px;
    margin-bottom: 140px;
  }

  & > .text {
    color: #fff;
    font-size: 28px;
    max-width: 874px;
    text-align: center;
    font-weight: 400;
  }
}

@media (max-width: 850px) {
  .header-block-content {
    padding-top: 200px;
  }
}

@media (max-width: 640px) {
  .header-block-content {
    & > img {
      width: 270px;
      margin-bottom: 130px;
    }

    & > .text {
      font-size: 18px;
      font-weight: 400;
      line-height: 132%;
      letter-spacing: -0.18px;
    }
  }

  .header-block-root {
    max-height: 670px;
  }
}
</style>
